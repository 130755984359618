
import api from '../_services/apiUrl'
import appConstants from "../_services/applicationConstant";
import {authHeader,encodedURL} from '../_helpers/auth-header'
import { handleResponse } from '../_helpers/handle-response';
import {encryptData} from '../Utils';
import {decryptData} from '../Utils';

export function  checkJWTTokenExpiry() {

   
    const salt = process.env.SALT || process.env.REACT_APP_KEY;
        const originalData = decryptData(localStorage.getItem("jwtExpiry"), salt);
    var jwtTokenExp = originalData;
    if (jwtTokenExp !== null && jwtTokenExp !== "") {
      var currentDate = new Date().toUTCString();
      var jwtDate = new Date(jwtTokenExp).toUTCString();
      if (jwtDate < currentDate) {  
          //localStorage.clear(); 
        return true;}
      else return true;
    } else return true;
  }

export default class authenticationService {
   
    // Initializing important variables
    constructor(domain) {
        
        this.domain = domain || api.proxyUrl + api.url // API
        this.fetch = this.fetch.bind(this) 
        this.login = this.login.bind(this)
        this.proxy=api.proxyPost
        this.url=api.url
        //this.setIsAuthenticated(false)
        //this.getProfile = this.getProfile.bind(this)
    }
   
    getCustomerLocation() {
        
        const salt = process.env.SALT || process.env.REACT_APP_KEY;
        const originalData = decryptData(localStorage.getItem("id_token"), salt);
        
        if(originalData !== null && originalData !== "" ){
        return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/company/location?companyNumber=${localStorage.getItem('companyNumber')}&customerNumber=${localStorage.getItem('customerNumber')}`)}`, {
            method: 'GET',
            headers: authHeader()
        }).then(response => response.json())
            .then((result) => {
                if (result != null && result !== "")
                    localStorage.setItem('customerLocation', result.Info);               
        });
    }
    }
    getCustomerAddress() {
        const salt = process.env.SALT || process.env.REACT_APP_KEY;
        const originalData = decryptData(localStorage.getItem("id_token"), salt);
        if(originalData !== null && originalData !== "" ){
        return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/customer/address?companyNumber=${localStorage.getItem('companyNumber')}&customerNumber=${localStorage.getItem('customerNumber')}`)}`, {
            method: 'GET',
            headers: authHeader()
        }).then(response => response.json())
            .then((result) => {
                if (result != null && result !== "")
                    localStorage.setItem('customerAddress', result.CustomerName);
            })
        }
    }
    login(username, password,companynumber,logintype,ssoFlag) {
        // Get a token from api server using the fetch api

       
        const userobject=username+':'+password+':'+companynumber+':'+logintype+':'+ssoFlag+':'+appConstants.isR3App;
   
    const token=window.btoa(userobject)
          return this.fetch(`${this.domain}/user/get_token?userToken=${token}`, {
            method: 'GET'
        
        }).then((res) => {           
                     console.log("At auth" + res)

            this.setToken(res.JWTToken) // Setting the token in localStorage
            this.setMenu(res)
            this.setCompany(companynumber);
            this.setCustomer(username);
            this.setUserName(username);
            this.setSSOFlag(ssoFlag);
            
            this.setIdentityKey(res.IdentityKey);
            this.setLoginTypeCode(logintype);
            this.setIsAuthenticated(true);
            this.setExpirationSpan(res.JWTExpiration);
            return Promise.resolve(res);           
        })
}

refreshToken() {
    // Get a token from api server using the fetch api
  //  const userobject=username+':'+password+':'+companynumber+':'+logintype+':'+ssoFlag+':'+appConstants.isR3App;

//const token=window.btoa(userobject)
      return this.fetch(`${this.domain}/user/get_Jwtrefreshtoken`, {
        method: 'GET',
        headers: authHeader()
    
    }).then((res) => {           
        this.setToken(res.JWTToken) // Setting the token in localStorage
        this.setMenu(res)
        
        
        
        this.setIsAuthenticated(true);
        this.setExpirationSpan(res.JWTExpiration);
        return Promise.resolve(res);            
    })
}
loginSubmit(username, password,companynumber,logintype,ssoFlag) {
       const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            'FullUserid':username,
            'LoginTypeCode': logintype,
            'CompanyNumber': companynumber,
            'Password': password,
            'AmphirePasswordFlag': "",
            'ReturnCode': "",
            'MiscFlag':appConstants.isR3App,
            'Url':`${this.url}/user/get_tokenAmphere`
          })
    };

    return fetch(`${this.proxy}`, requestOptions)
    .then(handleResponse => handleResponse.json())
 
    .then(user => {
      
        this.setToken(user.JWTToken) // Setting the token in localStorage
        this.setMenu(user)
        this.setCompany(companynumber);
        this.setCustomer(username);
        this.setUserName(username);
        this.setSSOFlag(ssoFlag);
       
        this.setIdentityKey(user.IdentityKey);
        this.setLoginTypeCode(logintype);
        this.setIsAuthenticated(true);
        this.setExpirationSpan(user.JWTExpiration);
        return Promise.resolve(user);        
    });
};
 loginAmphere(username, password,companynumber,logintype,ssoFlag) {
    // Get a token from api server using the fetch api
   // const userobject=username+':'+password+':'+companynumber+':'+logintype+':'+amphireFlag+':'+appConstants.isR3App;

   const formData = new FormData();

   formData.append('fullUserid', username);
   formData.append('loginTypeCode', logintype);
   formData.append('companyNumber', companynumber);
   formData.append('password', password);
   formData.append('amphirePasswordFlag', ssoFlag);
   formData.append('miscFlag', appConstants.isR3App );
      return this.fetch(`${this.domain}/user/get_tokenAmphere`, {
        method: 'POST',
        
          body:formData,
          
    }).then((res) => {           
         
        this.setToken(res.JWTToken) // Setting the token in localStorage
            this.setMenu(res)
            this.setCompany(companynumber);
            this.setCustomer(username);
            this.setUserName(username);
            this.setSSOFlag(ssoFlag);
            //this.setPassword(password);
            this.setIdentityKey(res.IdentityKey);
            this.setLoginTypeCode(logintype);
            this.setIsAuthenticated(true);
            this.setExpirationSpan(res.JWTExpiration);
        return Promise.resolve(res);           
    })
}
loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken() 
    return !!token && !this.isTokenExpired(token) 
}

isTokenExpired(token) {
    try {
        const decoded = token;
        if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
            return true;
        }
        else
            return false;
    }
    catch (err) {
        return false;
    }
}
setUserName(username){
    username=username.toUpperCase();
    localStorage.setItem('userName',username)
}
getUserName(username){
    return localStorage.getItem('userName')
}

 setCompany(companynumber) {
    // Saves user token to localStorage
    localStorage.setItem('companyNumber', companynumber)
}

setSSOFlag(ssoFlag) {
    localStorage.setItem('ssoFlag', ssoFlag)
}

setPassword(password) {
    // Saves user token to localStorage
    localStorage.setItem('password', password)
}


setCustomer(username) {
    // Saves user token to localStorage
    localStorage.setItem('customerNumber', username.substring(0,6))
}
getCompany() {
    // Retrieves the user token from localStorage
    return localStorage.getItem('companyNumber')
}
getCustomer() {
    // Retrieves the user token from localStorage
    return localStorage.getItem('customerNumber')
}

setToken(idToken) {
    // Saves user token to localStorage
    //localStorage.setItem('id_token', idToken)

    const salt = process.env.SALT || process.env.REACT_APP_KEY;
    const encryptedData = encryptData(idToken, salt);
    localStorage.setItem('id_token', encryptedData)

}

setExpirationSpan(expirationSpan){
   
    //localStorage.setItem('jwtExpiry', expirationSpan)
    const salt = process.env.SALT || process.env.REACT_APP_KEY;
    const encryptedData = encryptData(expirationSpan, salt);
    localStorage.setItem('jwtExpiry', encryptedData)
}


setIdentityKey(identityKey) {
    // Saves identityKey token to localStorage
    localStorage.setItem('identityKey', identityKey)
}

getIdentityKey() {
    // Retrieves the identityKey token from localStorage
    return localStorage.getItem('identityKey')
}
setLoginTypeCode(loginTypeCode) {
    // Saves identityKey token to localStorage
    localStorage.setItem('loginTypeCode', loginTypeCode)
}

getLoginTypeCode() {
    // Retrieves the identityKey token from localStorage
    return localStorage.getItem('loginTypeCode')
}

setIsAuthenticated(flag){
    localStorage.setItem('isSessionValid', flag)
}

getIsAuthenticated() {
    // Retrieves the identityKey token from localStorage
    return localStorage.getItem('isSessionValid');
}
setMenu(userobject)
{

    const salt = process.env.SALT || process.env.REACT_APP_KEY;
    //const encryptedData = encryptData(JSON.stringify(userobject), salt);
    const encryptedData = encryptData(userobject, salt);
    localStorage.setItem('UserMenu', encryptedData);
   // localStorage.setItem('UserMenu', JSON.stringify(userobject))
}
getMenu()
{
   
    const salt = process.env.SALT || process.env.REACT_APP_KEY;
    const originalData = decryptData(localStorage.getItem("UserMenu"), salt);

    //return JSON.parse(localStorage.getItem('UserMenu') ) 

    return originalData;
}
getToken() {
    // Retrieves the user token from localStorage
    let mkLocalData = localStorage.getItem('id_token');
    var jwtTokenExp ="";
    if(mkLocalData)
   {
    const salt = process.env.SALT || process.env.REACT_APP_KEY;
      const originalData = decryptData(localStorage.getItem("id_token"), salt);
      if(originalData !== null && originalData !== "" )
      {
    jwtTokenExp = originalData;
   }
   return originalData;
   }

   else
   {
    return jwtTokenExp;
   }
    // const salt = process.env.SALT || process.env.REACT_APP_KEY;
    //     const originalData = decryptData(localStorage.getItem("id_token"), salt);
   // return localStorage.getItem('id_token')
   
}

logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem('id_token');
}




fetch(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {
        //'Accept': 'application/json',
        'Content-Type': 'application/json',
        //'Access-Control-Allow-Origin':'*',
        //"Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With"
        //'Access-Control-Allow-Origin':'*',
        //'Access-Control-Request-Headers': "Content-Type, Accept, Authorization"
  
    }

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    if (this.loggedIn()) {
        headers['Authorization'] = 'Bearer ' + this.getToken()
    }

    return fetch(url, {
        headers,
        ...options
    })
        .then(this._checkStatus)
        .then(response => response.json())
}

_checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
        return response
    } else {
        var error = new Error(response.statusText)
        error.response = response
        throw error
    }
}
}
