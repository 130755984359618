import React, { Component } from "react";
import { styles } from "../assets/css/style.css";
import NavigationSelfService from "../components/NavigationSelfService";
import CustomerContact from "../components/CustomerContact";
import bunzlLogo from "../assets/images/bunzl-logo.gif";
import r3Logo from "../assets/images/r3-logo.gif";
import onlineChat from "../assets/images/onlinechat.gif";
import onlineR3Chat from "../assets/images/R3-chat-support-icon.png";
import appConstants from "../_services/applicationConstant";

class HeaderSelfService extends Component {
  render() {
    const loginTypeCode = localStorage.getItem("loginTypeCode");
    const companycode=localStorage.getItem('companyNumber');
    return (
      <div class="container-fluid" >
        <div class="row" >
          <div class="col-md-1 logo">
            {appConstants.isR3App ? (
              <a
                target="_blank"
                title="Click for Company Home page"
                href="http://www.r3safety.com/"
              >
                <img src={r3Logo} alt="R3" />
              </a>
            ) : (
              <a
                target="_blank"
                title="Click for Company Home page"
                href="http://www.bunzldistribution.com/"
              >
                <img src={bunzlLogo} alt="Bunzl" />
              </a>
            )}
          </div>
          <div class="col-md-10 headerColSpace nav-section text-center">
            <NavigationSelfService isR3App={appConstants.isR3App} />
            <small>
              <CustomerContact isR3App={appConstants.isR3App} />
            </small>
          </div>
          <div class="col-md-1 logoColSpace">
            <a
              href="https://app.velaro.com/chat?siteId=20913&groupId=0&showwhen=inqueue&secure=yes"
              target="_blank" style={{float:"right"}}
            >
               {appConstants.isR3App==true &&(companycode=="50") ? (
                <img src={onlineR3Chat} style={{width: "70px",height: "70px"}} alt="" />
              ) :appConstants.isR3App==false && (loginTypeCode==="C"||loginTypeCode==="G" ||loginTypeCode==="E") && (
                <img  alt="" />
              )}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderSelfService;
